import * as RadioGroup from "@radix-ui/react-radio-group";
import { isEmpty, isNil } from "lodash";
import React, { forwardRef, JSXElementConstructor } from "react";
import { Icon, IconProps } from "../Common/Icons/LucideIcons";
import type Stitches from "@stitches/react";
import {
  StyledSelectionCardGroup,
  SelectionCard,
  SelectionCardTextBlock,
  SelectionCardText,
} from "./__styles__/SelectionCardGroup";
import { colors } from "common-client/utils/styling";

export interface SelectionCardOption {
  /**
   * An ID value is required for the React `key` prop. Consider using
   * an actual unique identifier or a combination of the name of the
   * field and its value
   */
  id: string;
  value: string;
  title: string;
  description?: string;
  iconName?: IconProps["iconName"];
}

export interface SelectionCardGroupProps
  extends Stitches.VariantProps<typeof StyledSelectionCardGroup>,
    Omit<RadioGroup.RadioGroupProps, "onChange"> {
  options: Array<SelectionCardOption>;
  onChange: (value: string) => void;
}

// Utility-type for combining props from Radix component props
// and styled component props
type RadixRefStyledComponentProps<
  RadixType,
  StyledComponent extends
    | keyof JSX.IntrinsicElements
    | JSXElementConstructor<any>
> = Omit<RadixType, "asChild"> & React.ComponentProps<StyledComponent>;

type ForwardRefSelectionCardProps = RadixRefStyledComponentProps<
  RadioGroup.RadioGroupItemProps,
  typeof SelectionCard
> &
  SelectionCardOption;

const ForwardRefSelectionCard = forwardRef<
  HTMLButtonElement,
  ForwardRefSelectionCardProps
>((props, forwardedRef) => {
  const { id, value, title, description, iconName, ...refProps } = props;

  return (
    <SelectionCard {...refProps} ref={forwardedRef}>
      {!isNil(iconName) && !isEmpty(iconName) && (
        <Icon iconName={iconName} color={"contentSecondary"} size={16} />
      )}
      <SelectionCardTextBlock>
        <SelectionCardText size="small" type="emphasis">
          {title}
        </SelectionCardText>
        {!isNil(description) && !isEmpty(description) && (
          <SelectionCardText
            css={{ color: colors.contentSecondary, margin: "0 !important" }}
            size="small"
            type="regular"
          >
            {description}
          </SelectionCardText>
        )}
      </SelectionCardTextBlock>
    </SelectionCard>
  );
});

type ForwardRefSelectionCardGroupProps = RadixRefStyledComponentProps<
  RadioGroup.RadioGroupProps,
  typeof StyledSelectionCardGroup
> & {
  // property name pulled from console.log-ing props passed to component
  "data-orientation"?: "vertical" | "horizontal";
};

const ForwardRefSelectionCardGroup = forwardRef<
  HTMLDivElement,
  ForwardRefSelectionCardGroupProps
>((props, forwardedRef) => {
  const direction =
    props["data-orientation"] === "vertical" ? "column" : undefined;

  return (
    <StyledSelectionCardGroup
      {...props}
      direction={direction}
      ref={forwardedRef}
    />
  );
});

const SelectionCardGroup = ({
  onChange,
  options,
  borderStyle,
  spacingStyle,
  ...radioGroupProps
}: SelectionCardGroupProps) => {
  return (
    <RadioGroup.Root onValueChange={onChange} {...radioGroupProps} asChild>
      <ForwardRefSelectionCardGroup
        borderStyle={borderStyle}
        spacingStyle={spacingStyle}
      >
        {options.map(({ id, value, ...optionProps }) => (
          <RadioGroup.Item key={id} id={id} value={value} asChild>
            <ForwardRefSelectionCard id={id} value={value} {...optionProps} />
          </RadioGroup.Item>
        ))}
      </ForwardRefSelectionCardGroup>
    </RadioGroup.Root>
  );
};

SelectionCardGroup.displayName = "SelectionCardGroup";

export default SelectionCardGroup;
